import "styles/pages/page-post.scss";

import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import BlogWidgetPosts from "components/BlogWidgets/BlogWidgetPosts";

const News = ({ pageContext, data }) => {
	const dataPage = pageContext.data;

	return (
		<Layout headerColor="#0260DB">
			<Seo
				title={dataPage.seo.title ? dataPage.seo.title : dataPage.title}
				description={dataPage.seo.metaDesc && dataPage.seo.metaDesc}
			/>
			<SubpageHeader
				image={require("assets/images/news-top-bg.jpg").default}
				borderColor="#0260DB"
			/>

			<section className="post">
				<div className="container">
					<div className="row align-items-end">
						<div className="col-xl-7">
							<h2 className="section-title">{dataPage.title}</h2>
						</div>

						<div className="col-xl-5">
							<div className="post__info">
								<span className="post__author-thumb"></span>
								<strong>Autor:</strong>
								<span>Amazing Thailand</span>
								<div>
									<span className="post__info-shape"></span>
									<strong>Data:</strong>
									{dataPage.date
										.split("T")[0]
										.split("-")
										.reverse()
										.join(".")}
								</div>
							</div>
						</div>
					</div>

					<div className="post__content">
						<div className="row justify-content-center">
							<div className="col-xl-7 col-lg-8">
								<div
									dangerouslySetInnerHTML={{
										__html: dataPage.content,
									}}
								/>
								<div className="post__nav">
									{pageContext.next && (
										<Link
											to={`/wiadomosci-podroznicze/${pageContext.next.slug}/`}
											className="button button--yellow"
										>
											Poprzedni wpis
										</Link>
									)}
									{pageContext.prev && (
										<Link
											to={`/wiadomosci-podroznicze/${pageContext.prev.slug}/`}
											className="button button--yellow"
										>
											Następny wpis
										</Link>
									)}
								</div>
							</div>

							<div className="col-xl-3 col-lg-4">
								<div className="post__widget">
									<h4>
										Ostatnie wiadomości dla podróżujących:
									</h4>
									<BlogWidgetPosts
										data={data?.allNews}
										dataPage={dataPage}
										parentSlug="wiadomosci-podroznicze"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export const query = graphql`
	query {
		allNews: allWpNew(limit: 5) {
			nodes {
				id
				slug
				uri
				title
				date
				featuredImage {
					node {
						sourceUrl
					}
				}
			}
		}
	}
`;

export default News;
